import { v4 as uuid } from 'uuid';

import { DataLabel } from '@breathelife/meta-cruncher';
import {
  AgreeField,
  ApplicationContextSelectOptionsConfig,
  AutocompleteField,
  ButtonField,
  ComputedValueNodeWithReadOnlyProperty,
  DynamicOptionField,
  DynamicOptions,
  emptyTitle,
  Field,
  InformationField,
  InfoSupplement,
  InfoVariants,
  NodeIdToAnswerPathMap,
  NumberField,
  PlaceholderField,
  Question,
  QuestionnaireDefinition,
  QuoterSubsection,
  RadioField,
  RepeatableQuestion,
  RepeatableSectionGroup,
  Section,
  SectionGroup,
  SelectOption,
  SignatureField,
  SubmissionSubsectionLayout,
  Subsection,
  SubsectionVariantOptions,
  Validations,
  ValidityRule,
} from '@breathelife/questionnaire-engine';
import {
  AddressAutocompleteFieldBlueprint,
  AlwaysValid,
  BlueprintConditionsValue,
  BlueprintConditionsValueWithMessage,
  BooleanFieldValidation,
  ConditionBlueprintType,
  DateFieldValidation,
  DynamicOptionsBlueprint,
  FieldBlueprint,
  InformationFieldBlueprint,
  InformationFieldBlueprintVariant,
  InfoSupplementBlueprint,
  InputFieldValidation,
  InsuranceModule,
  isAcceptingDefaultValueField,
  isAddressAutocompleteFieldBlueprint,
  isAgreeFieldBlueprint,
  isButtonFieldBlueprint,
  isInformationFieldBlueprint,
  isNumberFieldBlueprint,
  isQuoterSubsectionBlueprint,
  isRadioFieldBlueprint,
  isSelectOptionFieldBlueprint,
  isSignatureFieldBlueprint,
  Language,
  Localizable,
  MoneyFieldValidation,
  NumberFieldValidation,
  OptionSize,
  PhoneFieldValidation,
  Query,
  QuestionBlueprint,
  QuestionnaireBlueprint,
  QuestionnaireBlueprintBase,
  QuestionnaireBlueprintCopyableOption,
  FieldTypes,
  QuestionnaireBlueprintRenderOn,
  RenderingType,
  SectionBlueprint,
  SectionGroupBlueprint,
  SectionGroupKey,
  SelectOptionBlueprint,
  StringFieldValidation,
  SubsectionBlueprint,
  SubsectionVariant,
} from '@breathelife/types';

import { ConditionsBuilder } from './blueprintToConditions';

class QuestionnaireConstructor {
  conditionBuilder: ConditionsBuilder;

  constructor(blueprint: QuestionnaireBlueprint, nodeIdAnswerPathMap?: NodeIdToAnswerPathMap) {
    this.conditionBuilder = new ConditionsBuilder(blueprint, nodeIdAnswerPathMap);
  }

  buildQuestionnaire(questionnaireBlueprint: QuestionnaireBlueprint): QuestionnaireDefinition {
    // This is a hack only for Hybrid Origination, it keeps the order of the section groups we use in our platform
    // Since we previously had the order of the section hardcoded, the section groups in the database are not ordered properly and do not display correctly without code
    // TODO: Update the blueprints to contain the section groups in the correct order for all questionnaire versions
    const sectionGroupKeys = Object.keys(questionnaireBlueprint.sectionGroupBlueprints);
    if (sectionGroupKeys.includes('insuredPeople') && sectionGroupKeys.includes('contract')) {
      const insuredPeopleSectionGroup = this.buildSectionGroup('insuredPeople', questionnaireBlueprint);
      const contractSectionGroup = this.buildSectionGroup('contract', questionnaireBlueprint);

      return [insuredPeopleSectionGroup, contractSectionGroup].filter(Boolean) as SectionGroup[];
    }

    const questionnaire: SectionGroup[] = [];
    for (const sectionGroupKey of Object.keys(questionnaireBlueprint.sectionGroupBlueprints)) {
      const sectionGroup = this.buildSectionGroup(sectionGroupKey, questionnaireBlueprint);
      if (sectionGroup) {
        questionnaire.push(sectionGroup);
      }
    }

    return questionnaire;
  }

  private buildSectionGroup(
    sectionGroupKey: SectionGroupKey,
    questionnaireBlueprint: QuestionnaireBlueprint,
  ): SectionGroup | null {
    const { sectionBlueprints, sectionGroupBlueprints } = questionnaireBlueprint;

    const visibleSectionGroupBlueprints = sectionBlueprints.filter(forVisibleSectionGroupBlueprints);
    if (!visibleSectionGroupBlueprints.length) {
      return null;
    }

    const sectionGroupBlueprint = sectionGroupBlueprints?.[sectionGroupKey];

    if (!sectionGroupBlueprint) {
      return null;
    }

    const sectionGroup: SectionGroup = {
      id: sectionGroupKey,
      blueprintId: sectionGroupBlueprint.id,
      referenceLabel: sectionGroupBlueprint.referenceLabel,
      index: '',
      title: partialToLocalizable(sectionGroupBlueprint?.title) ?? emptyTitle,
      sections: visibleSectionGroupBlueprints.map((blueprint) => this.buildSection(blueprint)),
      ...createRepeatableProperties(sectionGroupBlueprint),
    };

    return this.removeUndefinedProperties(sectionGroup);

    function forVisibleSectionGroupBlueprints(sectionBlueprint: SectionBlueprint): boolean {
      return isVisible(sectionBlueprint) && belongsToSectionGroup(sectionGroupKey, sectionBlueprint);
    }

    function isVisible(sectionBlueprint: SectionBlueprint): boolean {
      return !sectionBlueprint.hidden;
    }

    function belongsToSectionGroup(sectionGroupKey: SectionGroupKey, sectionBlueprint: SectionBlueprint): boolean {
      return sectionBlueprint.sectionGroupKey === sectionGroupKey;
    }

    function createRepeatableProperties(
      sectionGroupBlueprint?: SectionGroupBlueprint,
    ): Partial<RepeatableSectionGroup> {
      const result: Partial<RepeatableSectionGroup> = {};

      if (!sectionGroupBlueprint?.repeatable) {
        return result;
      }

      result.nodeId = sectionGroupBlueprint.repeatable.repeatableAnswerNodeId;

      result.options = {
        repeatable: true,
        minRepetitions: sectionGroupBlueprint.repeatable.minRepeatable,
        maxRepetitions: sectionGroupBlueprint.repeatable.maxRepeatable,
      };

      if (sectionGroupBlueprint.effects) {
        result.effects = sectionGroupBlueprint.effects;
      }

      return result;
    }
  }

  private buildSection(sectionBlueprint: SectionBlueprint): Section {
    const { text, title } = this.getTextAndTitle(sectionBlueprint);
    const subsections = sectionBlueprint.subsections
      .filter(this.filterHidden)
      .map((subsectionBlueprint) => this.buildSubsection(subsectionBlueprint, sectionBlueprint));

    const section: Section = {
      blueprintId: sectionBlueprint.id,
      referenceLabel: sectionBlueprint.referenceLabel,
      subsections,
      text,
      title: title ?? emptyTitle,
      id: sectionBlueprint.partName,
      index: '',
      visibleIf: this.conditionBuilder.buildConditions(sectionBlueprint.visible),
      dataLabel: sectionBlueprint.dataLabel,
      documentTypes: sectionBlueprint.pdfDocuments ?? [],
      insuranceModuleFilter: this.undefinedIfEmpty(sectionBlueprint.modules),
      platformTypeFilter: this.undefinedIfEmpty(sectionBlueprint.platforms),
      renderingTypeFilter: this.getRenderingTypeFilter(sectionBlueprint.renderOn, sectionBlueprint.modules),
      iconName: sectionBlueprint.iconName,
    };

    return this.removeUndefinedProperties(section);
  }

  private buildSubsection(subsectionBlueprint: SubsectionBlueprint, sectionBlueprint: SectionBlueprint): Subsection {
    const { text, title, nextStepButtonText } = this.getSubsectionLocalizable(subsectionBlueprint);

    const questions = subsectionBlueprint.questions
      .filter(this.filterHidden)
      .map((questionBlueprint) => this.buildQuestion(questionBlueprint, subsectionBlueprint, sectionBlueprint));

    if (isQuoterSubsectionBlueprint(subsectionBlueprint)) {
      const quoterSubsectionVariantQuestion = this.buildQuoterSubsectionVariantQuestion(
        subsectionBlueprint,
        sectionBlueprint,
      );
      if (quoterSubsectionVariantQuestion) {
        questions.push(quoterSubsectionVariantQuestion);
      }
    }

    const variantOptions = this.buildVariantOptions(subsectionBlueprint);

    const subsection: Subsection = {
      blueprintId: subsectionBlueprint.id,
      referenceLabel: subsectionBlueprint.referenceLabel,
      questions,
      text,
      title: title ?? emptyTitle,
      nextStepButtonText,
      id: subsectionBlueprint.partName,
      index: '',
      visibleIf: this.conditionBuilder.buildConditions(subsectionBlueprint.visible),
      dataLabel: subsectionBlueprint.dataLabel,
      variant: subsectionBlueprint.variant?.type ?? subsectionBlueprint.internal?.variant,
      platformTypeFilter: this.undefinedIfEmpty(subsectionBlueprint.platforms),
      renderingTypeFilter: this.getRenderingTypeFilter(subsectionBlueprint.renderOn),
      pageBreakSubSectionInPdf: subsectionBlueprint.pageBreakSubSectionInPdf,
      iconName: subsectionBlueprint.iconName,
      showInNavigation: subsectionBlueprint.showInNavigation,
    };

    if (variantOptions) {
      return this.removeUndefinedProperties({ ...subsection, variantOptions });
    }

    return this.removeUndefinedProperties(subsection);
  }

  private buildQuoterSubsectionVariantQuestion(
    subsectionBlueprint: SubsectionBlueprint,
    sectionBlueprint: SectionBlueprint,
  ): Question | undefined {
    if (subsectionBlueprint.variant?.type !== SubsectionVariant.quoter) {
      return undefined;
    }

    const useNeedsAnalysisNodeId = subsectionBlueprint.variant?.simpleQuoter?.useNeedsAnalysisNodeId;
    const partNamePrefix = useNeedsAnalysisNodeId ? 'simpleQuoter' : 'quoter';

    const productFieldBlueprint: FieldBlueprint = {
      id: uuid(),
      partName: `${partNamePrefix}VariantSubsectionProductField`,
      answerNodeId: subsectionBlueprint?.variant.productNodeIds.product,
      fieldType: FieldTypes.input,
      validateAs: InputFieldValidation.string,
    };

    const coverageAmountFieldBlueprint: FieldBlueprint = {
      id: uuid(),
      partName: `${partNamePrefix}VariantSubsectionCoverageAmountField`,
      answerNodeId: subsectionBlueprint.variant?.productNodeIds.coverageAmount,
      fieldType: FieldTypes.money,
      validateAs: MoneyFieldValidation.integer,
    };

    const questionFields: FieldBlueprint[] = [];

    if (useNeedsAnalysisNodeId) {
      const useNeedsAnalysisFieldBlueprint: FieldBlueprint = {
        id: uuid(),
        partName: `${partNamePrefix}VariantSubsectionUseNeedsAnalysisField`,
        answerNodeId: useNeedsAnalysisNodeId,
        fieldType: FieldTypes.checkbox,
        validateAs: BooleanFieldValidation.boolean,
      };

      questionFields.push(useNeedsAnalysisFieldBlueprint);

      const visibilityCondition: BlueprintConditionsValue = {
        booleanOperator: 'and',
        conditions: [
          {
            value: false,
            type: ConditionBlueprintType.equality,
            isEqual: true,
            targetNodeId: useNeedsAnalysisNodeId,
          },
        ],
      };

      productFieldBlueprint.visible = visibilityCondition;
      coverageAmountFieldBlueprint.visible = visibilityCondition;
    }

    questionFields.push(productFieldBlueprint, coverageAmountFieldBlueprint);

    const quoterVariantQuestion = {
      id: `quoter-variant-${uuid()}`,
      partName: `${partNamePrefix}VariantSubsectionQuestions`,
      fields: questionFields,
    };

    return this.buildQuestion(quoterVariantQuestion, subsectionBlueprint, sectionBlueprint);
  }

  private buildQuestion(
    questionBlueprint: QuestionBlueprint,
    subsectionBlueprint: SubsectionBlueprint,
    sectionBlueprint: SectionBlueprint,
  ): Question {
    const { text, title } = this.getTextAndTitle(questionBlueprint);

    const fields = questionBlueprint.fields
      .filter(this.filterHidden)
      .map((fieldBlueprint) =>
        this.buildField(fieldBlueprint, questionBlueprint, subsectionBlueprint, sectionBlueprint),
      );

    const question: Question = {
      blueprintId: questionBlueprint.id,
      referenceLabel: questionBlueprint.referenceLabel,
      fields,
      text,
      title,
      id: questionBlueprint.partName,
      visibleIf: this.conditionBuilder.buildConditions(questionBlueprint.visible),
      dataLabel: questionBlueprint.dataLabel,
      platformTypeFilter: this.undefinedIfEmpty(questionBlueprint.platforms),
      renderingTypeFilter: this.getRenderingTypeFilter(questionBlueprint.renderOn),
      showInPdfWithoutFields: questionBlueprint.internal?.showInPdfWithoutFields,
      displayAsCard: questionBlueprint.displayAsCard,
    };

    if (questionBlueprint.repeatable?.repeatableAnswerNodeId) {
      const repeatableQuestion = this.buildRepeatableQuestion(
        question,
        questionBlueprint,
        questionBlueprint.repeatable.repeatableAnswerNodeId,
      );
      return repeatableQuestion;
    }

    return this.removeUndefinedProperties(question);
  }

  private buildRepeatableQuestion(
    question: Question,
    questionBlueprint: QuestionBlueprint,
    nodeId: string,
  ): RepeatableQuestion {
    const repeatableValues = questionBlueprint.repeatable;
    if (!repeatableValues) {
      throw new Error(
        `On partName ${questionBlueprint.partName}: values.repeatable must be provided for repeatable questions`,
      );
    }

    const {
      id,
      referenceLabel,
      title,
      text,
      fields,
      visibleIf,
      dataLabel,
      platformTypeFilter,
      showInPdfWithoutFields,
      displayAsCard,
    } = question;
    const repeatableQuestion: RepeatableQuestion = {
      blueprintId: questionBlueprint.id,
      id,
      nodeId,
      referenceLabel,
      title,
      text,
      fields,
      visibleIf,
      dataLabel,
      addQuestionButtonText: this.getLocalizableFromPartial(repeatableValues.addButtonText),
      removeQuestionButtonText: this.getLocalizableFromPartial(repeatableValues.removeButtonText),
      options: {
        repeatable: true,
        minRepetitions: repeatableValues.minRepeatable ?? 1,
        maxRepetitions: repeatableValues.maxRepeatable ?? 3,
      },
      platformTypeFilter,
      showInPdfWithoutFields,
      displayAsCard,
      effects: questionBlueprint.effects,
    };

    return this.removeUndefinedProperties(repeatableQuestion);
  }

  private buildField(
    fieldBlueprint: FieldBlueprint,
    questionBlueprint: QuestionBlueprint,
    subsectionBlueprint: SubsectionBlueprint,
    sectionBlueprint: SectionBlueprint,
  ): Field {
    const encryptedDataLabels = [DataLabel.PHI, DataLabel.PII, DataLabel.IdentifiableDate, DataLabel.IdentifiablePHI];
    const type = this.getFieldType(fieldBlueprint);
    const validationType = this.getValidationType(fieldBlueprint);
    const { text, title } = this.getTextAndTitle(fieldBlueprint);
    const nodeId = fieldBlueprint.answerNodeId;
    const selectOptions = this.buildSelectOptions(fieldBlueprint);
    const selectOptionsFromApplicationContext = this.buildSelectOptionsFromApplicationContext(fieldBlueprint);

    // Check for `relatesTo` links while processing the field it will be set on.
    // (not mutating fieldBlueprints that are passed into `blueprintToQuestionnaire` makes thinking about this code easier)
    const relatesTo =
      this.getRelatesToForAddressAutocompleteFields(fieldBlueprint, questionBlueprint) ??
      fieldBlueprint.internal?.relatesTo;

    const field: Field = {
      blueprintId: fieldBlueprint.id,
      referenceLabel: fieldBlueprint.referenceLabel,
      text,
      title,
      nodeId,
      relatesTo,
      id: fieldBlueprint.partName,
      type,
      validation: { type: validationType },
      options: selectOptions,
      optionsFromApplicationContext: selectOptionsFromApplicationContext,
      optional: fieldBlueprint.optional,
      disabled: fieldBlueprint.disabled,
      dataLabel: fieldBlueprint.dataLabel,
      visibleIf: this.conditionBuilder.buildConditions(fieldBlueprint.visible),
      platformTypeFilter: this.undefinedIfEmpty(fieldBlueprint.platforms),
      renderingTypeFilter: this.getRenderingTypeFilter(fieldBlueprint.renderOn),
      iconName: fieldBlueprint.iconName,
      triggerStepNavigation: fieldBlueprint.triggerStepNavigation,
      layout: fieldBlueprint.layout,
      info: this.buildInfoSupplement(fieldBlueprint.infoSupplement),
      encrypted: encryptedDataLabels.some(
        (encryptedDataLabel) =>
          (fieldBlueprint.dataLabel ??
            questionBlueprint.dataLabel ??
            subsectionBlueprint.dataLabel ??
            sectionBlueprint.dataLabel) === encryptedDataLabel,
      ),
      displayInCardPreview: fieldBlueprint.displayInCardPreview,
      effects: fieldBlueprint.effects,
      copyable: overrideCopyableByHierarchy(sectionBlueprint, subsectionBlueprint, questionBlueprint, fieldBlueprint),
      applicationModes: fieldBlueprint.applicationModes,
    };

    if (isAcceptingDefaultValueField(fieldBlueprint)) {
      field.defaultValue = fieldBlueprint.defaultValue;
    }

    if (fieldBlueprint.valid) {
      const validIfFieldProperty = this.getFieldValidIf(fieldBlueprint.valid);

      if (validIfFieldProperty) {
        field.validIf = validIfFieldProperty;
      }
    }
    if (fieldBlueprint.internal?.computedValue) {
      (field as Field & ComputedValueNodeWithReadOnlyProperty).readOnly = true;
      field.computedValue = fieldBlueprint.internal.computedValue as Query;
    }

    if (isInformationFieldBlueprint(fieldBlueprint)) {
      const informationField = field as InformationField;
      informationField.readOnly = true;
      informationField.variant = this.getInformationFieldVariant(fieldBlueprint);
    }

    if (isButtonFieldBlueprint(fieldBlueprint)) {
      (field as ButtonField).buttonText = partialToLocalizable(fieldBlueprint.buttonText) ?? emptyTitle;
    }

    if (isAddressAutocompleteFieldBlueprint(fieldBlueprint)) {
      (field as AutocompleteField).countryCode = fieldBlueprint.countryCode;
      (field as AutocompleteField).nodeIdsToUpdate = this.getNodeIdsToUpdate(fieldBlueprint, questionBlueprint);
    }

    if (isRadioFieldBlueprint(fieldBlueprint)) {
      (field as RadioField).optionSize = fieldBlueprint.optionSize ?? OptionSize.half;
    }

    if (isAgreeFieldBlueprint(fieldBlueprint)) {
      (field as AgreeField).confirmedLabel = partialToLocalizable(fieldBlueprint.confirmedLabel) ?? emptyTitle;
      (field as AgreeField).modalHeader = partialToLocalizable(fieldBlueprint.modalHeader) ?? emptyTitle;
      (field as AgreeField).modalText = partialToLocalizable(fieldBlueprint.modalText) ?? emptyTitle;
    }

    if (isNumberFieldBlueprint(fieldBlueprint)) {
      (field as NumberField).numericalDataType = fieldBlueprint.numericalDataType;
    }

    if (isSelectOptionFieldBlueprint(fieldBlueprint)) {
      (field as DynamicOptionField).searchable = fieldBlueprint.searchable;
      if (fieldBlueprint.dynamicOptions) {
        const dynamicOptions = this.buildDynamicOptions(fieldBlueprint.dynamicOptions);

        if (dynamicOptions) {
          (field as DynamicOptionField).dynamicOptions = dynamicOptions;
        }
      }
    }

    if (isSignatureFieldBlueprint(fieldBlueprint)) {
      (field as SignatureField).participantRole = fieldBlueprint.participantRole;
      (field as SignatureField).readOnly = true;
    }

    if (fieldBlueprint.placeholder) {
      (field as PlaceholderField).placeholder = partialToLocalizable(fieldBlueprint.placeholder);
    }

    return this.removeUndefinedProperties(field);

    function overrideCopyableByHierarchy(
      sectionBlueprint: SectionBlueprint,
      subsectionBlueprint: SubsectionBlueprint,
      questionBlueprint: QuestionBlueprint,
      fieldBlueprint: FieldBlueprint,
    ): QuestionnaireBlueprintCopyableOption | undefined {
      if (sectionBlueprint.copyable && sectionBlueprint.copyable !== QuestionnaireBlueprintCopyableOption.none) {
        return sectionBlueprint.copyable;
      }
      if (subsectionBlueprint.copyable && subsectionBlueprint.copyable !== QuestionnaireBlueprintCopyableOption.none) {
        return subsectionBlueprint.copyable;
      }
      if (questionBlueprint.copyable && questionBlueprint.copyable !== QuestionnaireBlueprintCopyableOption.none) {
        return questionBlueprint.copyable;
      }
      if (fieldBlueprint.copyable && fieldBlueprint.copyable !== QuestionnaireBlueprintCopyableOption.none) {
        return fieldBlueprint.copyable;
      }
      return undefined;
    }
  }

  private buildSelectOptions(fieldBlueprint: FieldBlueprint): SelectOption[] | undefined {
    if (!isSelectOptionFieldBlueprint(fieldBlueprint)) {
      return undefined;
    }

    if (!fieldBlueprint.selectOptions.length) {
      return undefined;
    }

    const selectOptions = fieldBlueprint.selectOptions
      .filter(this.filterHidden)
      .map((selectOptionBlueprint) => this.buildSelectOption(selectOptionBlueprint));

    return selectOptions;
  }

  private buildSelectOptionsFromApplicationContext(
    fieldBlueprint: FieldBlueprint,
  ): ApplicationContextSelectOptionsConfig | undefined {
    if (!isSelectOptionFieldBlueprint(fieldBlueprint)) {
      return undefined;
    }

    if (!fieldBlueprint.selectOptionsApplicationContext) {
      return undefined;
    }

    const { tag, labelKey, valuePath } = fieldBlueprint.selectOptionsApplicationContext;

    if (!tag || !valuePath || !labelKey) {
      return undefined;
    }

    if (!labelKey.en && !labelKey.fr) {
      return undefined;
    }

    return { tag, labelKey, valuePath };
  }

  private buildDynamicOptions(dynamicOptions: DynamicOptionsBlueprint): DynamicOptions | undefined {
    // Somewhat defensively build the `DynamicOptions` from the untyped `internal` property.

    const collection = dynamicOptions.collection;
    const select = dynamicOptions.select;
    const visible = this.conditionBuilder.buildConditions(dynamicOptions.visible);

    if (collection && select) {
      const dynamicOptions: DynamicOptions = {
        collection,
        select,
      };

      if (visible) {
        dynamicOptions.visibleIf = visible;
      }

      return dynamicOptions;
    }

    return undefined;
  }

  private buildSelectOption(selectOptionBlueprint: SelectOptionBlueprint): SelectOption {
    const optionText = partialToLocalizable(selectOptionBlueprint.text);

    const selectOption: SelectOption = {
      id: selectOptionBlueprint.partName,
      text: optionText ?? emptyTitle,
      visibleIf: this.conditionBuilder.buildConditions(selectOptionBlueprint.visible),
      checked: selectOptionBlueprint.internal?.checked,
      platformTypeFilter: selectOptionBlueprint.internal?.platformTypeFilter,
      orderingIndex: selectOptionBlueprint.orderingIndex,
    };

    return this.removeUndefinedProperties(selectOption);
  }

  private buildInfoSupplement(infoSupplementBlueprint?: InfoSupplementBlueprint): InfoSupplement | undefined {
    if (!infoSupplementBlueprint) {
      return undefined;
    }

    const infoSupplement: InfoSupplement = {
      text: this.getLocalizableFromPartial(infoSupplementBlueprint.text),
    };

    if (infoSupplementBlueprint.title) {
      infoSupplement.title = this.getLocalizableFromPartial(infoSupplementBlueprint.title);
    }

    if (infoSupplementBlueprint.image) {
      infoSupplement.image = infoSupplementBlueprint.image;

      // If we have an image it should be full size on mobile ... this probably shouldn't even be toggleable in the engine.
      infoSupplement.modalOptions = { forceModal: true, fullSizeOnMobile: true };
    }

    return infoSupplement;
  }

  private buildVariantOptions(subsectionBlueprint: SubsectionBlueprint): SubsectionVariantOptions | undefined {
    const { internal } = subsectionBlueprint;

    // TODO: Remove this once these variants are supported more fully by the editor.
    if (internal?.variant === SubsectionVariant.submission && isSubmissionVariantOptions(internal.variantOptions)) {
      return internal.variantOptions;
    }

    if (!subsectionBlueprint.variant) {
      return undefined;
    }

    switch (subsectionBlueprint.variant.type) {
      case SubsectionVariant.quoter: {
        return this.buildQuoterVariantOptions(subsectionBlueprint);
      }

      case SubsectionVariant.submission:
        return {
          layout: subsectionBlueprint.variant.displayInline ? SubmissionSubsectionLayout.flow : undefined,
          hideHeader: subsectionBlueprint.variant.hideHeader,
        };

      default:
        return undefined;
    }
  }

  private buildQuoterVariantOptions(subsectionBlueprint: SubsectionBlueprint): QuoterSubsection['variantOptions'] {
    if (!subsectionBlueprint || subsectionBlueprint?.variant?.type !== SubsectionVariant.quoter)
      throw new Error('Cannot build the subsection variant for quoter');

    const productNodeIds = subsectionBlueprint.variant.productNodeIds;
    if (!productNodeIds) {
      throw new Error('productNodeIds must be provided for a subsection variant of type quoter');
    }

    const useNeedsAnalysisNodeId = subsectionBlueprint.variant.simpleQuoter?.useNeedsAnalysisNodeId;

    if (useNeedsAnalysisNodeId) {
      return {
        simpleQuoter: true,
        coverageAmountNodeId: subsectionBlueprint.variant.productNodeIds.coverageAmount,
        productNodeId: subsectionBlueprint.variant.productNodeIds.product,
        useNeedsAnalysisNodeId,
      };
    }

    return {
      simpleQuoter: false,
      coverageAmountNodeId: subsectionBlueprint.variant.productNodeIds.coverageAmount,
      productNodeId: subsectionBlueprint.variant.productNodeIds.product,
    };
  }

  private getFieldType(fieldBlueprint: FieldBlueprint): FieldTypes {
    let fieldType: FieldTypes;

    switch (fieldBlueprint.fieldType) {
      case FieldTypes.input: {
        fieldType = FieldTypes.input;
        break;
      }
      case FieldTypes.checkbox: {
        fieldType = FieldTypes.checkbox;
        break;
      }
      case FieldTypes.date: {
        if (
          [
            DateFieldValidation.yearMonth,
            DateFieldValidation.yearMonthPastDate,
            DateFieldValidation.yearMonthPastOrCurrentDate,
            DateFieldValidation.yearMonthFutureDate,
            DateFieldValidation.yearMonthFutureOrCurrentDate,
          ].includes(fieldBlueprint.validateAs as DateFieldValidation)
        ) {
          // The engine has separate types for 'date' and 'yearMonth'. We don't make this distinction in the editor though.
          fieldType = FieldTypes.yearMonth;
          break;
        }

        fieldType = FieldTypes.date;
        break;
      }
      case FieldTypes.number: {
        fieldType = FieldTypes.number;
        break;
      }
      case FieldTypes.money: {
        fieldType = FieldTypes.money;
        break;
      }
      case FieldTypes.textarea: {
        fieldType = FieldTypes.textarea;
        break;
      }
      case FieldTypes.dropdown: {
        fieldType = FieldTypes.dropdown;
        break;
      }
      case FieldTypes.checkboxGroup: {
        fieldType = FieldTypes.checkboxGroup;
        break;
      }
      case FieldTypes.radio: {
        fieldType = FieldTypes.radio;
        break;
      }
      case FieldTypes.phone: {
        fieldType = FieldTypes.phone;
        break;
      }
      case FieldTypes.information: {
        fieldType = FieldTypes.information;
        break;
      }
      case FieldTypes.button: {
        fieldType = FieldTypes.button;
        break;
      }
      case FieldTypes.currencyCard: {
        fieldType = FieldTypes.currencyCard;
        break;
      }
      case FieldTypes.autocomplete: {
        fieldType = FieldTypes.autocomplete;
        break;
      }
      case FieldTypes.agree: {
        fieldType = FieldTypes.agree;
        break;
      }
      case FieldTypes.signature: {
        fieldType = FieldTypes.signature;
      }
    }

    return fieldType;
  }

  private getValidationType(fieldBlueprint: FieldBlueprint): Validations {
    // TODO: check valid validation types per field type
    const hasValidValidationType = true;
    if (!hasValidValidationType) {
      throw Error(
        `Invalid validation type '${fieldBlueprint.validateAs}' for field type '${fieldBlueprint.fieldType}'.`,
      );
    }

    let validationType: Validations;

    switch (fieldBlueprint.validateAs) {
      case StringFieldValidation.string:
      case InputFieldValidation.string: {
        validationType = Validations.string;
        break;
      }
      case InputFieldValidation.email: {
        validationType = Validations.email;
        break;
      }
      case PhoneFieldValidation.phone: {
        validationType = Validations.phoneNumber;
        break;
      }
      case InputFieldValidation.sin: {
        validationType = Validations.sin;
        break;
      }
      case InputFieldValidation.ssn: {
        validationType = Validations.ssn;
        break;
      }
      case InputFieldValidation.canadianPostalCode: {
        validationType = Validations.canadianPostalCode;
        break;
      }
      case InputFieldValidation.zipCode: {
        validationType = Validations.zipCode;
        break;
      }
      case InputFieldValidation.branchNumber: {
        validationType = Validations.branchNumber;
        break;
      }
      case InputFieldValidation.institutionNumber: {
        validationType = Validations.institutionNumber;
        break;
      }
      case InputFieldValidation.accountNumber: {
        validationType = Validations.accountNumber;
        break;
      }
      case InputFieldValidation.firstName: {
        validationType = Validations.firstName;
        break;
      }
      case InputFieldValidation.lastName: {
        validationType = Validations.lastName;
        break;
      }
      case InputFieldValidation.middleName: {
        validationType = Validations.middleName;
        break;
      }
      case NumberFieldValidation.integer:
      case MoneyFieldValidation.integer: {
        validationType = Validations.integer;
        break;
      }
      case NumberFieldValidation.decimal:
      case MoneyFieldValidation.decimal: {
        validationType = Validations.decimal;
        break;
      }
      case NumberFieldValidation.percentage: {
        validationType = Validations.percentage;
        break;
      }
      case DateFieldValidation.date: {
        validationType = Validations.date;
        break;
      }
      case DateFieldValidation.pastDate: {
        validationType = Validations.pastDate;
        break;
      }
      case DateFieldValidation.pastOrCurrentDate: {
        validationType = Validations.pastOrCurrentDate;
        break;
      }
      case DateFieldValidation.futureOrCurrentDate: {
        validationType = Validations.futureOrCurrentDate;
        break;
      }
      case DateFieldValidation.currentDate: {
        validationType = Validations.currentDate;
        break;
      }
      case DateFieldValidation.yearMonth:
        validationType = Validations.yearMonth;
        break;
      case DateFieldValidation.yearMonthPastDate:
        validationType = Validations.yearMonthPastDate;
        break;
      case DateFieldValidation.yearMonthFutureOrCurrentDate:
        validationType = Validations.yearMonthFutureOrCurrentDate;
        break;
      case DateFieldValidation.yearMonthPastOrCurrentDate:
        validationType = Validations.yearMonthPastOrCurrentDate;
        break;
      case DateFieldValidation.futureDate:
        validationType = Validations.futureDate;
        break;
      case DateFieldValidation.yearMonthFutureDate:
        validationType = Validations.yearMonthFutureDate;
        break;
      case BooleanFieldValidation.boolean: {
        validationType = Validations.boolean;
        break;
      }
      case BooleanFieldValidation.booleanTrue: {
        validationType = Validations.booleanTrue;
        break;
      }
      case AlwaysValid.signature: {
        validationType = Validations.mixed;
      }
    }

    return validationType;
  }

  private getRenderingTypeFilter(
    renderOn?: QuestionnaireBlueprintRenderOn[],
    modules?: InsuranceModule[],
  ): RenderingType[] | undefined {
    const hasNeedsAnalysisModule = !!modules?.includes(InsuranceModule.needsAnalysis);

    if (!renderOn && !hasNeedsAnalysisModule) {
      return undefined;
    }

    const renderingTypes: RenderingType[] = [];

    if (renderOn?.includes(QuestionnaireBlueprintRenderOn.web)) {
      renderingTypes.push(RenderingType.web);
    }
    if (renderOn?.includes(QuestionnaireBlueprintRenderOn.pdf)) {
      renderingTypes.push(RenderingType.pdf);
    }
    if (renderOn?.includes(QuestionnaireBlueprintRenderOn.summary)) {
      renderingTypes.push(RenderingType.consumerSummary);
    }

    // NOTE: RenderingType.proSummary should hopefully be deprecated soon. For now it should be set whenever `InsuranceModule.needsAnalysis` is set.
    if (hasNeedsAnalysisModule) {
      renderingTypes.push(RenderingType.proSummary);
    }

    if (renderingTypes.length) {
      return renderingTypes;
    }

    // No flags set means inherit from parent (no flags set on this level).
    return undefined;
  }

  //** Will return an associate with the _first_ `addressAutocompleteFieldBlueprint` linked to `fieldPartName`. */
  private getRelatesToForAddressAutocompleteFields(
    fieldBlueprint: FieldBlueprint,
    questionBlueprint: QuestionBlueprint,
  ): string | undefined {
    if (isAddressAutocompleteFieldBlueprint(fieldBlueprint)) {
      // Address autocomplete fields will never be linked to an address autocomplete field (to itself or another field).
      return undefined;
    }

    const addressAutocompleteBlueprints = questionBlueprint.fields.filter(
      (item) => this.filterHidden(item) && isAddressAutocompleteFieldBlueprint(item) && item.addressAutocompleteFields,
    ) as AddressAutocompleteFieldBlueprint[];

    if (!addressAutocompleteBlueprints.length) {
      return undefined;
    }

    for (const addressAutocompleteBlueprint of addressAutocompleteBlueprints) {
      const isAssociatedWithAutocompleteBlueprint =
        fieldBlueprint.partName === addressAutocompleteBlueprint.addressAutocompleteFields.streetAddress ||
        fieldBlueprint.partName === addressAutocompleteBlueprint.addressAutocompleteFields.city ||
        fieldBlueprint.partName === addressAutocompleteBlueprint.addressAutocompleteFields.stateOrProvince ||
        fieldBlueprint.partName === addressAutocompleteBlueprint.addressAutocompleteFields.postalCodeOrZip;

      if (isAssociatedWithAutocompleteBlueprint) {
        return addressAutocompleteBlueprint.addressAutocompleteNodeId || addressAutocompleteBlueprint.answerNodeId;
      }
    }
  }

  private getNodeIdsToUpdate(
    fieldBlueprint: AddressAutocompleteFieldBlueprint,
    questionBlueprint: QuestionBlueprint,
  ): AutocompleteField['nodeIdsToUpdate'] {
    const { addressAutocompleteFields } = fieldBlueprint;

    const nodeIdsToUpdate: AutocompleteField['nodeIdsToUpdate'] = {
      autocomplete: fieldBlueprint.addressAutocompleteNodeId || fieldBlueprint.answerNodeId,
      streetAddress: '',
      city: '',
      provinceOrState: '',
      postalOrZipCode: '',
    };

    if (!addressAutocompleteFields) {
      // This should not happen, and there is a migration that should prevent this code from running (to get to that migration we need to get past this point though)
      return nodeIdsToUpdate;
    }

    if (addressAutocompleteFields.streetAddress) {
      const streetAddressBlueprint = questionBlueprint.fields.find(
        (item) => item.partName === addressAutocompleteFields.streetAddress,
      );
      if (streetAddressBlueprint) {
        nodeIdsToUpdate.streetAddress = streetAddressBlueprint.answerNodeId;
      } else {
        throw new Error(
          `streetAddress field blueprint '${addressAutocompleteFields.streetAddress}' was not found under question '${questionBlueprint.partName}'`,
        );
      }
    }

    if (addressAutocompleteFields.city) {
      const cityBlueprint = questionBlueprint.fields.find((item) => item.partName === addressAutocompleteFields.city);
      if (cityBlueprint) {
        nodeIdsToUpdate.city = cityBlueprint.answerNodeId;
      } else {
        throw new Error(
          `city field blueprint '${addressAutocompleteFields.city}' was not found under question '${questionBlueprint.partName}'`,
        );
      }
    }

    if (addressAutocompleteFields.stateOrProvince) {
      const provinceOrStateBlueprint = questionBlueprint.fields.find(
        (item) => item.partName === addressAutocompleteFields.stateOrProvince,
      );
      if (provinceOrStateBlueprint) {
        nodeIdsToUpdate.provinceOrState = provinceOrStateBlueprint.answerNodeId;
      } else {
        throw new Error(
          `provinceOrState field blueprint '${addressAutocompleteFields.stateOrProvince}' was not found under question '${questionBlueprint.partName}'`,
        );
      }
    }

    if (addressAutocompleteFields.postalCodeOrZip) {
      const postalCodeOrZipBlueprint = questionBlueprint.fields.find(
        (item) => item.partName === addressAutocompleteFields.postalCodeOrZip,
      );
      if (postalCodeOrZipBlueprint) {
        nodeIdsToUpdate.postalOrZipCode = postalCodeOrZipBlueprint.answerNodeId;
      } else {
        throw new Error(
          `postalOrZipCode field blueprint '${addressAutocompleteFields.postalCodeOrZip}' was not found under question '${questionBlueprint.partName}'`,
        );
      }
    }

    return nodeIdsToUpdate;
  }

  private undefinedIfEmpty<T>(input?: T[]): T[] | undefined {
    if (!input?.length) {
      return undefined;
    }

    return input;
  }

  private getLocalizableFromPartial(partial?: Partial<Localizable>): Localizable {
    if (!partial) return emptyTitle;

    return {
      ...emptyTitle,
      ...partial,
    };
  }

  private getTextAndTitle(blueprint: QuestionnaireBlueprintBase): { text?: Localizable; title?: Localizable } {
    return {
      text: partialToLocalizable(blueprint.text),
      title: partialToLocalizable(blueprint.title),
    };
  }

  private getSubsectionLocalizable(blueprint: SubsectionBlueprint): {
    text?: Localizable;
    title?: Localizable;
    nextStepButtonText?: Localizable;
  } {
    const { text, title } = this.getTextAndTitle(blueprint);

    return {
      text,
      title,
      nextStepButtonText: partialToLocalizable(blueprint.nextStepButtonText),
    };
  }

  private getFieldValidIf(
    validityCondition: BlueprintConditionsValueWithMessage[],
  ): ValidityRule<Localizable>[] | undefined {
    const validPropertyField: ValidityRule<Localizable>[] = [];

    validityCondition.forEach((item) => {
      const message = partialToLocalizable(item.message);
      const conditions = this.conditionBuilder.buildConditions(item.conditions);
      if (conditions && message) {
        validPropertyField.push({ conditions, message });
      }
    });

    if (!validPropertyField.length) {
      return undefined;
    }

    return validPropertyField;
  }

  private getInformationFieldVariant(blueprint: InformationFieldBlueprint): InfoVariants {
    switch (blueprint.variant) {
      case InformationFieldBlueprintVariant.error:
        return InfoVariants.error;
      case InformationFieldBlueprintVariant.info:
        return InfoVariants.info;
      case InformationFieldBlueprintVariant.success:
        return InfoVariants.success;
      case InformationFieldBlueprintVariant.warning:
        return InfoVariants.warning;
      case InformationFieldBlueprintVariant.paragraph:
        return InfoVariants.paragraph;
    }
  }

  private filterHidden<T extends { hidden?: boolean }>(blueprint: T): boolean {
    return !blueprint.hidden;
  }

  private removeUndefinedProperties<T extends Record<string, unknown>>(obj: T): T {
    Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);
    return obj;
  }
}

// TODO: Remove this function when Submission variants have better support in the editor.
function isSubmissionVariantOptions(
  variantOptions: unknown,
): variantOptions is { layout?: SubmissionSubsectionLayout; hideHeader?: boolean } {
  return !!variantOptions && !!(variantOptions as { layout?: SubmissionSubsectionLayout; hideHeader?: boolean }).layout;
}

function partialToLocalizable(partial: Partial<Localizable> | undefined): Localizable | undefined {
  if (!partial) return undefined;

  return {
    [Language.en]: partial.en ?? '',
    [Language.fr]: partial.fr ?? '',
  };
}

export function constructQuestionnaire(
  blueprint: QuestionnaireBlueprint,
  nodeIdAnswerPathMap: NodeIdToAnswerPathMap,
): QuestionnaireDefinition {
  const questionnaireConstructor = new QuestionnaireConstructor(blueprint, nodeIdAnswerPathMap);
  const questionnaire = questionnaireConstructor.buildQuestionnaire(blueprint);
  return questionnaire;
}
