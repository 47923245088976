import { ConditionalConsideration, ConsiderationBlueprint, QuestionnaireBlueprint } from '@breathelife/types';

import { ConditionsBuilder } from '../helpers/blueprintToConditions';
import { NodeIdToAnswerPathMap } from '@breathelife/questionnaire-engine';

export function generateSalesDecisionRuleConsiderations(
  questionnaireBlueprint: QuestionnaireBlueprint,
  nodeIdAnswerPathMap: NodeIdToAnswerPathMap,
  considerationBlueprints: ConsiderationBlueprint[],
): ConditionalConsideration[] {
  const conditionsBuilder = new ConditionsBuilder(questionnaireBlueprint, nodeIdAnswerPathMap);

  return considerationBlueprints.map((blueprint) =>
    generateSalesDecisionRuleConsideration(conditionsBuilder, blueprint),
  );
}

export function generateSalesDecisionRuleConsideration(
  conditionsBuilder: ConditionsBuilder,
  blueprint: ConsiderationBlueprint,
): ConditionalConsideration {
  const { outcomeCode, conditions, reason } = blueprint;

  const generatedConditions = conditionsBuilder.buildConditions(conditions);

  if (!generatedConditions) {
    throw new Error('Failed to generate conditions from considerationlueprint');
  }

  const consideration: ConditionalConsideration = {
    ...generatedConditions,
    outcomeCode,
    reason,
  };

  return consideration;
}
